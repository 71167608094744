<template>
  <div v-page-behavior="{eventSource: assist.dataType.EventSourceEnum.PRE_ORIGINATION_ENTER_LEAVE_CHOOSE_PAYMENT_MODE.value, path: $route.path}">
    <div class="main-content">
      <div class="py-6">
        <payment-mode-group
          v-if="layouts.actives.funding"
          v-model="layouts.data.mode.funding"
          :options="[{ id: 1, label: 'Electronic', description: 'Funds will be deposited within 24 hours of approval. *' }, { id: 2, label: 'Check by Mail', description: 'Funds will be sent through check by mail.' }]"
          @click="onClickFundingMode"
        ></payment-mode-group>
        <payment-mode-group
          v-else
          v-model="layouts.data.mode.repayment"
          :options="[{ id: 1, label: 'Electronic', description: 'Payment will be charged through ACH.' }, { id: 2, label: 'Check by Mail', description: 'Payment will be sent by paper check.' }]"
          @click="onClickPaymentMod"
        ></payment-mode-group>
      </div>
      <v-alert
        v-if="layouts.actives.funding"
        dense
        text
        border="left"
        color="var(--theme_primary)"
        class="f-s-12"
      >
        <span class="text-red">*</span>Unavoidable delays that occur due to bank holidays, processing schedule of your bank,
        processing errors, "acts of God" and/or "acts of terror" may extend the time for the deposit.
      </v-alert>
    </div>
    <div class="footer-wrap">
      <wap-btn
        :disabled-control="!(layouts.data.isClickElectronic || layouts.data.isClickCheckByMail)"
        :theme="!(layouts.data.isClickElectronic || layouts.data.isClickCheckByMail) ? '' : 'primary'"
        :block="true"
        width="100%"
        @click="onClickContinue"
      >Continue</wap-btn>
    </div>

    <wap-bottom-sheet
      v-model="layouts.dialogs.checkByMail.display"
      :persistent="true"
      title="Warning"
      @cancel="onClickNo"
      @confirm="onClickYes"
    >
      <v-card-text
        v-if="!layouts.actives.funding"
        class="f-w-300 f-s-12"
      >
        If you choose physical repayment,
        you <span class="font-weight-bold">cannot receive your loan proceeds electronically</span>.
        If approved, your loan proceeds will be mailed to you in <span class="font-weight-bold">5 business days</span>.
        Other limitations apply.
      </v-card-text>
      <v-card-text
        v-else
        class="f-w-300 f-s-12"
      >
        If approved, your loan proceeds will be mailed to you in <span class="font-weight-bold">5 business days</span>.
        Other limitations apply.
      </v-card-text>
    </wap-bottom-sheet>

    <wap-bottom-sheet
      v-model="layouts.dialogs.paperCheck.display"
      :persistent="true"
      title="Set Contact Time"
      @cancel="$refs.followUp.close()"
      @confirm="$refs.followUp.submit()"
    >
      <follow-up :personal="layouts.data.personal" ref="followUp" :primary-text="`We need more information based on your choice of repayment, please contact us
        at ${ layouts.data.portfolio.tollfreePhone }.`" @close="onCloseSelect" @submit="onConfirmHandler"></follow-up>
    </wap-bottom-sheet>

    <wap-bottom-sheet
      v-model="layouts.dialogs.confirm.display"
      :persistent="true"
      title="Please confirm your choices"
      @cancel="onClickCancel"
      @confirm="onClickOK"
    >
      <v-card-text class="my-2">
        <v-row class="text-center">
          <v-col cols="6">Repayment Mode:</v-col>
          <v-col cols="6">{{ layouts.data.mode.repayment === 0 ? 'Electronic' : 'Check By Mail' }}</v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="text-center">
          <v-col cols="6">Funding Mode:</v-col>
          <v-col cols="6">{{ layouts.data.mode.funding === 0 ? 'Electronic' : 'Check By Mail' }}</v-col>
        </v-row>
      </v-card-text>
    </wap-bottom-sheet>
  </div>
</template>

<script>
import PaymentModeGroup from '@/components/disbursement/PaymentModeGroup'
import WapBottomSheet from '@/components/base/WapBottomSheet'
import FollowUp from '@/components/disbursement/FollowUp'
import { OnlineApi } from '@/api'
import { DataType, Tool } from '@/assets/js/util'
import WapBtn from '@/components/base/WapBtn'

const tool = new Tool()
export default {
  name: 'ChoosePaymentMode',
  inject: ['reload'],
  components: { FollowUp, WapBottomSheet, PaymentModeGroup, WapBtn },
  data: () => ({
    assist: {
      dataType: DataType
    },
    layouts: {
      data: {
        portfolio: {
          tollfreePhone: ''
        },
        personal: {
          homePhone: '',
          cellPhone: ''
        },
        isClickElectronic: false,
        isClickCheckByMail: false,
        mode: {
          repayment: null,
          funding: null
        },
        chooseMode: ''
      },
      actives: {
        funding: false
      },
      dialogs: {
        checkByMail: {
          display: false
        },
        paperCheck: {
          display: false
        },
        confirm: {
          display: false
        }
      }
    }
  }),
  mounted () {
    this.getContactInfo()
    this.$store.commit('setSubtitle', 'Choose Repayment Mode')
  },
  watch: {
    'store.getters.getPortfolioJson': {
      handler () {
        this.layouts.data.portfolio = this.$store.getters.getPortfolioJson
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    onClickCancel () {
      this.layouts.dialogs.confirm.display = false
    },
    onClickOK () {
      this.$router.push('/disbursement/choose-bank-account')
    },
    getContactInfo () {
      OnlineApi.getPersonalData((result) => {
        if (tool.isNotEmpty(result)) {
          this.layouts.data.personal.homePhone = result.homePhone
          this.layouts.data.personal.cellPhone = result.cellPhone
        }
      })
    },
    onCloseSelect () {
      if (this.layouts.data.chooseMode === 'Payment') {
        this.layouts.data.mode.repayment = null
      } else {
        this.layouts.data.mode.funding = null
      }
      this.layouts.data.isClickCheckByMail = false
      this.layouts.dialogs.paperCheck.display = false
    },
    onClickApplyLoan () {
      const portfolioJson = this.$store.getters.getPortfolioJson
      tool.setPopupInformation(
        'We regret to inform you that ' + portfolioJson.name + ' is no longer originating new loans. ' +
        'But you still can log into your account to manage your existing loans. If you have any further questions, ' +
        'please contact us by calling us at ' + portfolioJson.tollfreePhone + ' or emailing us at ' + portfolioJson.email
      )
    },
    onConfirmHandler (data) {
      if (tool.isNotEmpty(data.followUpAt)) {
        const request = {
          cellPhone: data.cellPhone.replace(/[^0-9]/ig, ''),
          homePhone: data.homePhone.replace(/[^0-9]/ig, ''),
          defaultDisbursementMode: DataType.TransactionModeEnum.PAPER_CHECK.value,
          defaultRepaymentMode: DataType.TransactionModeEnum.PAPER_CHECK.value
        }
        OnlineApi.paperCheck(request, () => {
          this.layouts.dialogs.paperCheck.display = false
          this.$router.push('/notification/204')
        }, (err) => {
          this.$store.commit('setPopupInformation', {
            message: err.message
          })
        })
      }
    },
    onClickNo () {
      if (this.layouts.data.chooseMode === 'Payment') {
        this.layouts.data.mode.repayment = null
      } else {
        this.layouts.data.mode.funding = null
      }
      this.layouts.data.isClickCheckByMail = false
      this.layouts.dialogs.checkByMail.display = false
    },
    onClickYes () {
      this.layouts.dialogs.checkByMail.display = false
      this.layouts.dialogs.paperCheck.display = true
    },
    onClickPaymentMod (index) {
      this.layouts.data.chooseMode = 'Payment'
      this.onClickMode(index)
    },
    onClickFundingMode (index) {
      this.layouts.data.chooseMode = 'Funding'
      this.onClickMode(index)
    },
    onClickMode (index) {
      if (index === 1) {
        this.layouts.dialogs.checkByMail.display = true
        this.layouts.data.isClickCheckByMail = true
        this.layouts.data.isClickElectronic = false
      } else {
        this.layouts.data.isClickCheckByMail = false
        this.layouts.data.isClickElectronic = true
      }
    },
    onClickContinue () {
      if (this.layouts.actives.funding) {
        this.layouts.dialogs.confirm.display = true
      } else {
        this.layouts.actives.funding = true
        this.layouts.data.isClickElectronic = false
        this.layouts.data.isClickCheckByMail = false
        this.$store.commit('setSubtitle', 'Choose Funding Mode')
      }
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
