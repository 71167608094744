<template>
  <div style="max-width: 800px; margin: auto">

    <v-card-text class="pt-5">
      {{ primaryText }}
    </v-card-text>

    <v-card  class="overflow-x-hidden" flat>
      <v-card-title>
        Please select the best time and phone number to reach you.
      </v-card-title>
      <v-card-actions>
        <template>
          <v-row
            align="center"
            justify="space-around"
          >
            <div class="v-picker v-card v-picker--time theme--light" @click="timer = true">
              <div class="v-picker__title black">
                <div class="v-time-picker-title">
                  <div class="v-time-picker-title__time">
                    <div class="v-picker__title__btn v-picker__title__btn--active">{{ hour }}</div>
                    <span>:</span>
                    <div class="v-picker__title__btn">{{ minute }}</div>
                  </div>
                </div>
              </div>
            </div>
          </v-row>
        </template>
      </v-card-actions>
      <v-card-text class="p-b-0">
        <ValidationObserver ref="observer">
          <ValidationProvider
            v-slot="{ errors }"
            name="Primary Phone"
            rules="required|phone"
          >
            <wap-text-field
              v-model="cellPhone"
              v-mask="'(###) ###-####'"
              class="f-w-300"
              color="black"
              label="Primary Phone"
              :error-message="errors[0]"
            ></wap-text-field>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="Secondary Phone"
            rules="required|phone"
          >
            <wap-text-field
              v-model="homePhone"
              v-mask="'(###) ###-####'"
              class="f-w-300"
              color="black"
              label="Secondary Phone"
              :error-message="errors[0]"
            ></wap-text-field>
          </ValidationProvider>
        </ValidationObserver>
      </v-card-text>

    </v-card>
    <v-dialog v-model="timer">
      <v-time-picker
        v-model="picker"
        color="var(--theme_primary)"
        format="24hr"
        width="300"
        max="22:15"
        min="9:30"
        @click:minute="timer = false"
      ></v-time-picker>
    </v-dialog>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import WapTextField from '@/components/base/WapTextField'
import { Tool } from '@/assets/js/util'

const tool = new Tool()
export default {
  name: 'follow-up',
  components: {
    ValidationObserver,
    ValidationProvider,
    WapTextField
  },
  props: {
    personal: {
      type: Object,
      default: () => {}
    },
    primaryText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      picker: null,
      timer: false,
      cellPhone: '',
      homePhone: '',
      disabled: true,
      loading: false
    }
  },
  created () {
    this.cellPhone = this.personal.cellPhone
    this.homePhone = this.personal.homePhone
    this.loading = false
  },
  computed: {
    hour () {
      if (this.picker) {
        return this.picker.split(':')[0]
      } else {
        return '--'
      }
    },
    minute () {
      if (this.picker) {
        return this.picker.split(':')[1]
      } else {
        return '--'
      }
    }
  },
  watch: {
    hour () {
      this.updateDisabled()
    },
    minute () {
      this.updateDisabled()
    },
    homePhone () {
      this.updateDisabled()
    },
    cellPhone () {
      this.updateDisabled()
    }
  },
  methods: {
    updateDisabled () {
      const hasCellPhone = tool.isNotEmpty(this.cellPhone) && this.cellPhone.replace(/[^0-9]/ig, '').length === 10
      const hasHomePhone = tool.isNotEmpty(this.homePhone) && this.homePhone.replace(/[^0-9]/ig, '').length === 10
      this.disabled = !(this.hour !== '--' && this.hour !== '--' && hasCellPhone && hasHomePhone)
    },
    submit () {
      this.$refs.observer.validate().then(result => {
        if (result) {
          this.loading = true
          this.$emit('submit', { followUpAt: this.picker, cellPhone: this.cellPhone, homePhone: this.homePhone })
        }
      })
    },
    close () {
      this.$emit('close')
      this.homePhone = this.personal.homePhone
      this.cellPhone = this.personal.cellPhone
    }
  }
}
</script>
